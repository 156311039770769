import { ENVS } from './environments.constants';

export const environment = {
  production: false,
  apiUrl: `${window.origin}/api`,
  name: ENVS.DEV,
  cidiURl: 'https://cidi.test.cba.gov.ar',
  vediURl: 'https://vedi.stage.cordoba.gob.ar/VeDiLandingPage',
  consultaPadronesQR: 'https://calidadalimentariadigital.stage.cordoba.gob.ar/padrones/consulta-qr',
  filesUrl: 'https://dev-alimentos-s3-public.s3.amazonaws.com/'
};
